import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Skeleton, Typography, Box, Card, CardContent } from '@mui/material';
import { useUserManagement } from '../../../contexts/UserManagementContexts';

const GettingStarted = () => {
    const { user } = useAuth0();
    const { organizations, currentOrg, isLoading, isLoadingOrgs } = useUserManagement();


    return (
        <>
            <Card
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    m: '24px 0',
                    backgroundColor: '#dbd6ff'
                }}
            >
                <CardContent>
                    {
                        user ?
                            (
                                <Typography variant='h5'>
                                    Welcome, {user.name} 👋
                                </Typography>
                            )
                            :
                            (
                                <Skeleton variant='rectangular' width={300} height={35} />
                            )
                    }
                    <Box sx={{ m: '16px 0' }}>
                        {isLoading || isLoadingOrgs ? (
                            <Skeleton variant="rectangular" width="100%" height={200} />
                        ) : (
                            <>
                                {organizations.length > 0 ? 
                                (
                                    <Box sx={{maxWidth: '600px'}}>
                                        <p>Use the pane on the left to find what you need across your workspaces and sessions.</p>
                                        <p>Can't find what you need? Ask your team admin to raise a request for additional permissions.</p>
                                        <p>If you have any other questions or need technical support then contact us at <a href="mailto:support@hoppa.ai">support@hoppa.ai.</a></p>
                                    </Box>
                                ) 
                                : 
                                (
                                    <Box sx={{maxWidth: '600px'}}>
                                        <p>It looks like you're new here.</p>
                                        <p>If your organisation is already registered with us, you'll be notified as soon as access to your company's resources have been granted.</p>
                                        <p>If your organisation is new to Hoppa, we'd be delighted to offer you a free trial and will be in touch with the details.</p>
                                        <p>In the meantime, if you have specific questions you can always reach us as at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai.</a></p>
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>

                </CardContent>
            </Card>
        </>
    )
}

export default GettingStarted