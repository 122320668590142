import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import RequestADemo from './subcomponents/RequestADemo';
import NavBar from './shared/NavBar'
import FooterBar from './shared/FooterBar';
import Popup from './shared/Popup';
import ArticleCard from './shared/ArticleCard';
import { Box, Card, Chip, Grid, Stack, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MainHeader from './subcomponents/home/MainHeader';
import { ArrowDownwardOutlined, ExpandMore } from '@mui/icons-material';


const Integrations = () => {
    const [showModal, setShowModal] = useState(false)
    const [expanded, setExpanded] = useState('');

    const faqs = [
        {
            question: "What applications do you integrate with?",
            answer: `Hoppa integrates with Microsoft SharePoint Online and Autodesk Construction Cloud (BIM 360®, Docs). 

We also provide a CSV to row importer to enable indirect integration with tabular data from an unlimited range of other sources.

Our AI has been trained with knowledge about 1600+ file types and can extract content from:

- **Office formats**: DOCX, XLSX, PPTX
- **Common exchange formats**: PDF, IFC, STEP
- **Image formats**: PNG, JPG, TIFF, GIF, BMP, SVG
- **Text and markup formats**: TXT, CSV, JSON, HTML, XML

We’re adding new integrations all the time, so get in touch with details of your use case to [hello@hoppa.ai](mailto:hello@hoppa.ai).`
        },
        {
            question: "What is Hoppa?",
            answer: `Hoppa is the first-of-its-kind data refinery for the built environment, allowing you to cleanse, restructure and extract intelligence from whole-life asset data in a few clicks. 

Built using state-of-the-art AI, Hoppa’s technology augments your existing workflows as you manage your asset portfolio, gear up for construction handover, or ready for new regulation (e.g. ISO 19650). `
        },
        {
            question: "Why choose Hoppa?",
            answer: `Our services enable you to tackle data challenges at scales you'd previously only dreamt of. 

We tailor our offering to suit your needs - anywhere from licensing our technology through to data enrichment services led by our team - freeing you up to focus on the steps that add most value to your business.`
        },
    ]

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    return (
        <Box style={{
            backgroundColor: '#f0f8ff',
            minHeight: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0
        }}
        >
            <NavBar variant='blue' fixed={true} />
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    minHeight: '600px',
                    height: '100%',
                    maxHeight: '100vw',
                    zIndex: -1,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze.png)`,
                    backgroundSize: { xs: '100vw', md: '200vw' }, // Ensures the image covers the entire area
                    backgroundPosition: { xs: 'top 60px left 50%', md: 'top 90% right -125vw' },
                    backgroundRepeat: 'no-repeat', // Prevents tiling of the image
                }}
            ></Box>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: '0 2%',
                paddingTop: { xs: '100px', sm: '120px' },
                paddingBottom: '75px',
                display: 'flex',         // Enables flexbox
                flexDirection: 'column', // Ensures the content stacks vertically
                alignItems: 'center',    // Centers horizontally
                margin: '0 auto',        // Horizontally center the box
            }}>
                <Stack
                    spacing={4}
                    direction={{ xs: 'column-reverse', md: 'row' }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Chip
                            label='Integrations'
                            sx={{
                                backgroundColor: 'transparent',
                                border: '1px solid #0051b6',
                                color: '#0051b6',
                                m: '8px 0'
                            }}
                        />
                        <p className='blue-font xlarge-font bold-statement'>
                            Bridge silos and build consistency
                        </p>
                        <Typography sx={{ width: { xs: '100%', sm: '70%' } }}>
                            Pull from your favourite data sources and 17+ data types to assemble your single-source-of-truth for whole-life built asset data.
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <img
                            style={{
                                maxWidth: '450px',
                                width: '90%',
                            }}
                            src={`${process.env.PUBLIC_URL}/integrations-wheel.png`}
                        />
                    </Box>
                </Stack>
            </Box>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: { xs: '0 2%', sm: '0 10%' },
                display: 'flex',         // Enables flexbox
                flexDirection: 'column', // Ensures the content stacks vertically
                alignItems: 'center',    // Centers horizontally
                margin: '0 auto',        // Horizontally center the box
                marginBottom: '50px',
            }}>
                <Grid
                    container
                    justifyContent="flex-start" // Align the grid items to the left
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 12, md: 12 }}
                >
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ borderRadius: '16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    aspectRatio: '1.5',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box sx={{ width: '100%', aspectRatio: '2', overflow: 'hidden' }}>
                                    <img
                                        style={{ width: '100%' }}
                                        src={`${process.env.PUBLIC_URL}/integrations-benefit-1.png`}
                                    />
                                </Box>
                                <Stack direction='column' sx={{ textAlign: 'center' }}>
                                    <Box sx={{ margin: '32px' }}>
                                        <Typography variant='h5' sx={{ color: '#0051b6' }}>
                                            Simple to use
                                        </Typography>
                                        <Typography
                                            sx={{
                                                WebkitLineClamp: 3,        // Limits the text to 2 lines
                                                height: `calc(1.3em * 3)`,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            Connect in minutes with your user credentials – no coding skills required.
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ borderRadius: '16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    aspectRatio: '1.5',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box sx={{ width: '100%', aspectRatio: '2', overflow: 'hidden' }}>
                                    <img
                                        style={{ width: '100%', marginTop: '12%' }}
                                        src={`${process.env.PUBLIC_URL}/integrations-benefit-2.png`}
                                    />
                                </Box>
                                <Stack direction='column' sx={{ textAlign: 'center' }}>
                                    <Box sx={{ margin: '32px' }}>
                                        <Typography variant='h5' sx={{ color: '#0051b6' }}>
                                            Mix & match
                                        </Typography>
                                        <Typography
                                            sx={{
                                                WebkitLineClamp: 3,        // Limits the text to 2 lines
                                                height: `calc(1.3em * 3)`,
                                            }}
                                        >
                                            You choose which documents to analyse across your combined data stores, in batches or in bulk.
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ borderRadius: '16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    aspectRatio: '1.5',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box sx={{ width: '100%', aspectRatio: '2', overflow: 'hidden' }}>
                                    <img
                                        style={{ width: '100%', marginTop: '15%' }}
                                        src={`${process.env.PUBLIC_URL}/integrations-benefit-3.png`}
                                    />
                                </Box>
                                <Stack direction='column' sx={{ textAlign: 'center' }}>
                                    <Box sx={{ margin: '32px' }}>
                                        <Typography variant='h5' sx={{ color: '#0051b6' }}>
                                            Secure by design
                                        </Typography>
                                        <Typography
                                            sx={{
                                                WebkitLineClamp: 3,        // Limits the text to 2 lines
                                                height: `calc(1.3em * 3)`,
                                            }}
                                        >
                                            Documents stay put in the source system, so you retain control of who has access to your data.
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ borderRadius: '16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    aspectRatio: '1.5',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',

                                }}
                            >
                                <Box sx={{ width: '100%', aspectRatio: '2', overflow: 'hidden' }}>
                                    <img
                                        style={{ width: '100%', marginTop: '10%' }}
                                        src={`${process.env.PUBLIC_URL}/integrations-benefit-4.png`}
                                    />
                                </Box>
                                <Stack direction='column' sx={{ textAlign: 'center' }}>
                                    <Box sx={{ margin: '32px' }}>
                                        <Typography variant='h5'
                                            sx={{
                                                color: '#0051b6',
                                            }}>
                                            Send data anywhere
                                        </Typography>
                                        <Typography
                                            sx={{
                                                WebkitLineClamp: 3,        // Limits the text to 2 lines
                                                height: `calc(1.3em * 3)`,
                                            }}
                                        >
                                            You decide how, where and when enrichments are sent back to the source system, or shared to others.
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ backgroundColor: '#6f58ed', margin: '64px 0', minHeight: '150px', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        maxWidth: '1320px',
                        width: '90%',
                        padding: { xs: '32px 2%', sm: '32px 10%' },
                        margin: '0 auto',
                        minHeight: '100%',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column'
                    }}
                >
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ width: '100%', display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                        <Box sx={{ minWidth: '50%', color: 'white' }}>
                            <Typography variant='h5'>
                                On the look-out for a specific integration? Speak to the team today.
                            </Typography>
                        </Box>
                        <Box sx={{ minWidth: '50%', color: 'white', display: 'flex', justifyContent: 'center' }}>
                            <button className='btn btn-secondary mt-3' onClick={() => setShowModal(true)}>
                                Speak to the team
                            </button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box>
                <Container className='mt-5 mb-5 pb-5'>
                    <MainHeader
                        title='Integrations Resources'
                    />
                    <Grid
                        container
                        className='mt-1'
                        justifyContent="flex-start" // Align the grid items to the left
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 12, md: 12 }}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <ArticleCard
                                header="Integrations in action"
                                subHeader="Visit our YouTube channel and watch our tutorial videos to discover how easy it is to assemble your single source-of-truth."
                                imageURL={`${process.env.PUBLIC_URL}/youtube-link-integrations.png`}
                                tag='Tutorial'
                                duration='4-5 mins watch'
                                authorName='Hoppa'
                                authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                                publishedDate="Dec 15, '25"
                                linkURL="https://www.youtube.com/@HoppaTechnologies"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <ArticleCard
                                header="Is More Technology Really the Answer to Construction's Productivity Problem?"
                                subHeader="An exploration of the disconnect between tech innovation and meaningful transformation in the built environment."
                                imageURL="https://images.unsplash.com/photo-1561679543-696e0cf19f39?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMDAzMzh8MHwxfHNlYXJjaHwxNzZ8fGFic3RyYWN0JTIwc3RlZWx8ZW58MHx8fHwxNzI2ODMwNTQ0fDA&ixlib=rb-4.0.3&q=80&w=1080"
                                tag='Article'
                                duration='6-8 mins read'
                                authorName='Sam Rees'
                                authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                                publishedDate="Sep 20, '24"
                                linkURL="https://samrees.substack.com/p/is-more-technology-really-the-answer-to-constructions-productivity-problem-transformation-digital-data-innovation-rethink-contech-proptech"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <ArticleCard
                                header="Illustrative Use Case Pack"
                                subHeader="Discover the ways Hoppa supercharges teams day-to-day, and how much your business stands to save."
                                imageURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.png`}
                                tag='Use Cases'
                                duration='10-15 mins read'
                                authorName='Hoppa'
                                authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                                publishedDate="Sep 05, '24"
                                linkURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.pdf`}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container className='mt-5 mb-5'>
                    <MainHeader
                        title='Frequently Asked Questions'
                    />
                    <Box className='mt-3'>
                    {faqs.map((faq, index) => (
                        <>
                            <Accordion
                                onChange={handleChange(index)}
                                expanded={expanded === index}
                                sx={{
                                    color: '#0051b6',
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    fontSize: '16pt',
                                    minHeight: '50px',
                                    margin: '8px 0',
                                    '&::before': {
                                        backgroundColor: '#0051b6',
                                    },
                                    '&.Mui-expanded::before': {
                                        opacity: 1
                                    },
                                }}>
                                <AccordionSummary
                                    expandIcon={<ArrowDownwardOutlined sx={{ color: '#0051b6' }} />}
                                    id={index}
                                >
                                    {faq.question}
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        fontSize: '12pt',
                                        // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '16px',
                                        // padding: '32px 24px',
                                    }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: marked(faq.answer),
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )
                    )}
                    </Box>
                </Container>
            </Box>
            <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                title="Let's chat"
                image='labor-7576514_640.png'
                imageAlt='Get started'
                content={
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <RequestADemo sx={{ display: 'flex', flexGrow: 1 }} />
                    </Box>

                }
            />
            <footer style={{ marginTop: 0 }}>
                <FooterBar />
            </footer>
        </Box>
    )
}

export default Integrations