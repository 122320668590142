import React, { useState, useEffect, useCallback } from "react";
import { Grid, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, IconButton, Typography, Box, Divider, Button, Stack, Chip, Avatar } from '@mui/material';
import connectors from "../../../connectorConfig/connectorConfig.json"
import ConnectorCard from "../../shared/ConnectorCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCopy, faTrashCan, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import { handleAuth } from "../../../scripts/handleAuth";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserManagement, useCheckPermissions } from "../../../contexts/UserManagementContexts";
const AddDataSource = ({ organisation, workspace, sessionId, dataSources, unsavedChanges, handleCancelModal, selectedConnector, handleSetSelectedConnector, handleSetSelectedTab, handleSetSelectedPage, handleUpdateDataSource, handleDeleteDataSource, saveSession }) => {
    // User and permission details
    const { user, isLoading, isLoadingOrgs } = useUserManagement();

    // Permission Check
    const checkPermissions = useCheckPermissions();

    const [isTrial, setIsTrial] = useState(false);
    const [isConnect, setIsConnect] = useState(false)

    useEffect(() => {

        if (!isLoading && !isLoadingOrgs) {

            const trialCheck = checkPermissions(['test:files'])
            setIsTrial(trialCheck)

            const connectCheck = checkPermissions(['connect:sources'], organisation)
            setIsConnect(connectCheck)
        }

    }, [user, isLoading, isLoadingOrgs])

    const cancelAdd = () => {
        handleSetSelectedTab('Overview')
        handleSetSelectedPage('Overview')
        handleDeleteDataSource('New data source')
        handleSetSelectedConnector(null)
    }

    const configureDataSource = () => {

        if (dataSources.some(dataSource => dataSource.name === selectedConnector.displayName)) {
            
            handleSetSelectedTab(selectedConnector.displayName)
            handleDeleteDataSource('New data source')
        }

        if (!selectedConnector.isExternal) {
            handleUpdateDataSource('New data source', selectedConnector.displayName)
            handleSetSelectedTab(selectedConnector.displayName)
            handleSetSelectedPage('ConfigureDataSource')
        }

        else {
            saveSession()
            handleAuth(selectedConnector.key, organisation, workspace, sessionId, user.sub)
        }
        
    }

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1 }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Add data source
                    </Typography>
                    {/* Buttons Group */}
                    <Stack direction="row" spacing={1} sx={{
                        alignItems: "center",
                    }}>
                    </Stack>
                </Box>
            </div>
            <div style={{ width: '100%', marginBottom: '80px' }}>
                <Grid container spacing={3} alignItems="center">
                    {connectors.map((source, index) => (
                        <Grid item key={index}>
                            <ConnectorCard
                                title={source.displayName}
                                disabled={source.isExternal && !isConnect} // Disable if user doesn't have permissions
                                comingSoon={source.organisations.length === 0}
                                imageSrc={`${process.env.PUBLIC_URL}${source.displayImage}`}
                                isSelected={selectedConnector?.displayName === source.displayName}
                                onClick={() => handleSetSelectedConnector(source)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
            {/* Bottom action bar */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '64px', // Adjust the height as needed
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                    padding: '0 1.5rem'
                }}
            >
                {/* Cancel Button */}
                <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} onClick={() => cancelAdd()}>
                    <FontAwesomeIcon size='sm' icon={faBan} className='me-1' />
                    Cancel
                </Button>

                {/* Continue Button */}
                <button className='btn btn-primary mt-2' disabled={!selectedConnector} onClick={() => configureDataSource()}>
                    Continue
                </button>
            </Box>
        </Box>
    )
}

export default AddDataSource;