import React, { useState, useRef, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { pink } from '@mui/material/colors';
import { ArrowDownward, Logout } from '@mui/icons-material';

const HeaderBarUserDropdown = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgError, setImgError] = useState(false);  // Tracks if there's an error loading the image
  const [loading, setLoading] = useState(true);  // Tracks the loading state of the image
  const { logout } = useAuth0();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const dropdownRef = useRef(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className="custom-dropdown" style={{ position: 'relative' }} ref={dropdownRef}>
      {/* User image or icon with tooltip */}
      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
        <Tooltip title={user?.name || "Profile"} placement="right">
          <Avatar
            alt={user?.name.toUpperCase()}
            src="/static/images/avatar/1.jpg"
            onClick={handleOpenUserMenu}
            sx={{ 
              bgcolor: 'hotpink',
              height: 36,
              width: 36

            }}
          />
        </Tooltip>
      </div>
      <>
        <Menu
          sx={{ mt: '45px', '& .MuiPaper-root': { borderRadius: '8px' } }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Box sx={{ p: '12px 12px', pb: 0, minWidth: '250px' }}>
            <Stack
              direction='row'
              sx={{ alignItems: 'center', mb: '8px', p: '0 12px' }}
            >
              <Avatar
                alt={user?.name?.toUpperCase()}
                src="/static/images/avatar/1.jpg"
                sx={{
                  bgcolor: 'hotpink',
                  height: 30,
                  width: 30,
                  mr: '16px'
                }}
              />
              <Stack>
                <Typography>
                  {user?.name}
                </Typography>
                <Typography sx={{ color: 'gray' }}>
                  {user?.email}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ border: '1px solid lightgray', p: '0 12px' }} />
            <MenuItem sx={{ m: '8px 0' }}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              <ListItemText 
              sx={{ color: 'gray' }}
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              >Logout
              </ListItemText>
            </MenuItem>
          </Box>
        </Menu>
        {/* <div>
          <div
            className="dropdown-item"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            style={{
              cursor: 'pointer',
              padding: '10px',
              color: 'black',
            }}
          >
            Logout
          </div>
        </div> */}
      </>
    </div >
  );
};

export default HeaderBarUserDropdown;
