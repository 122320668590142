import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap'
import HeaderBar from './shared/HeaderBar';
import FooterBar from './shared/FooterBar';
import ReactGA from 'react-ga';
import NavBar from './shared/NavBar';

const TermsAndConditions = () => {

    useEffect(() => {
        document.title = "Terms & Conditions of Business | Hoppa"; // Set the browser tab title here
    }, []); // Empty dependency array ensures this runs only on component mount

    // Google analytics ping on component mount
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const htmlContent = `
  <article>
    <style>
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font - family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font - family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font - family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
        }
        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
            font - family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
        }
    </style>
    <div data-custom-class="body">
        <div>
            <strong><span style="font-size: 26px;">
                <span data-custom-class="title"><bdt class="block-component"></bdt>
                    <bdt class="question">TERMS & CONDITIONS OF BUSINESS</bdt>
                </span>
            </span>
            </strong>
            <div><br></div>
            <strong>
                <span style="font-size: 15px;">
                    <span data-custom-class="subtitle">Last updated <bdt class="question">January 09, 2025</bdt>
                    </span>
                </span>
            </strong>
        </div>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    These Terms of Business (“Agreement”) are between Hoppa Technologies Ltd (“Hoppa”, “we”, “us”, “our”) and the party identified as the customer (“Customer”, “you”, “your”) in the Proposal for Provision of Services (“Proposal”) and is effective as of the date the parties enter into an applicable agreement incorporating these terms (the “Effective Date”).
                    </span>
                </span>
            </span>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        In the event of a conflict among the documents making up this Agreement, the main body of this Agreement will prevail, except that any customer-specific terms, Data Processing Addendum, or any other exhibit, attachment, or addendum will control over the Agreement for its specific subject matter.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            1. DEFINITIONS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Affiliate</b>. Any entity that Controls, is Controlled by, or is under common Control with Hoppa or Customer entity agreeing to these terms, where "Control” means ownership of more than 50% of the voting interests of the subject entity or having the power to direct or cause the direction of the management and policies of an entity, whether through ownership, by contract, or otherwise.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Authorised Users</b>. Any individual who is authorised by Customer to use the Services, including Customer’s or its Affiliates’ employees, consultants, contractors, or agents.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Customer Data</b>. All information that Customer or its Authorised Users submit to the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Initial Term</b>. The initial term of this agreement which shall be set out in the Proposal.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Proposal</b>. A written document specifying the Services to be provided pursuant to this Agreement that is accepted by Customer. Each Proposal is a stand-alone agreement, separate from any other Proposal, incorporating the terms and conditions of this agreement by reference. 
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Service</b>. The service provided by Hoppa to Customer under this agreement via Hoppa’s proprietary technology solution (“System”), including Setup and Configuration Services, Core Services delivery, and any other work or services that it is agreed are to be provided by Hoppa to Customer pursuant to the Proposal. This definition encompasses professional services provided by Hoppa or its subcontractors and software services via Hoppa’s technology System.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Service Term</b>. Means the Initial Term together with any subsequent renewal periods.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>System</b>. A proprietary technology solution, developed by Hoppa as a service platform. 
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            2. SERVICES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.1. Provision of the Service and Availability</b>. Hoppa will make the Service available to Customer during the applicable, then-current Service Term pursuant to this Agreement and set out in the Proposal. Hoppa may update the content, functionality, and user interface of the Service from time to time provided such update will not materially decrease the functionality of the Service during the applicable, then-current Service Term. Customer agrees that its use of the Service under this Agreement is not contingent on the delivery of future features or functionality.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.2. Access Rights</b>. If access to the Hoppa beta web application is granted to Customer as explicitly set out in the Proposal, Hoppa grants Customer a non-exclusive, non-sublicensable, non-transferable (except as specifically permitted in Section 12.8) right to access and use (and to grant access and use of the Service to its Authorised Users) the Service during the period set out in the Proposal, solely for Customer’s internal business purposes.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.3. Affiliates</b>. In addition to any access rights a Customer Affiliate may have as an End User of Customer, a Customer Affiliate may separately acquire Subscriptions and/or Professional Services pursuant to this Agreement under a separate Proposal, and in each such case, all references in this Agreement to Customer will be deemed to refer to the applicable Affiliate for purposes of that Proposal.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.4. Acceptable Use Terms</b>. Customer will not (a) make the Service available to anyone other than Customer and its End Users or use the Service for the benefit of anyone other than Customer or its Affiliates; (b) rent, sublicense, re-sell, assign, distribute, time share or similarly exploit the Service;(c) reverse engineer, copy, modify, adapt, or hack the Service; (d) access the Service, the Documentation, or Hoppa’s Confidential Information to develop a product or service in competition with the Service; (e) use the Service to violate any applicable law, any third party’s intellectual property rights, or anyone’s right of privacy or publicity; (f) or use the Service to create, use, send, store, or run viruses, bots, worms, or similar harmful material. Hoppa may request that Customer suspend the account of any Authorised User who: (i) violates this Agreement; or (ii) is using the Service in a manner that Hoppa reasonably believes may cause a security risk or a disruption to others’ use of the Service. If Customer fails to promptly suspend or terminate such an Authorised User’s account, Hoppa reserves the right to do so.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.5. Security; Protection of Customer Data</b>. Hoppa will implement and maintain reasonable administrative, organizational, and technical safeguards designed for the protection, confidentiality, and integrity of Customer Data at least as rigorous as the measures described in the Backup Policy available at <a data-custom-class="link" href="https://hoppa.ai/backup-policy" rel="noopener noreferrer" target="_blank">https://hoppa.ai/backup-policy</a> and ‘Solution Evaluation against NCSC Cloud Security Principles’ (available upon request). Hoppa reserves the right to modify these documents from time to time provided such modification will not materially and adversely diminish the overall security of the Service during the applicable, then-current Service Term. In addition, either the Hoppa Data Processing Addendum, available at <a data-custom-class="link" href="https://hoppa.ai/data-processing-addendum" rel="noopener noreferrer" target="_blank">https://hoppa.ai/data-processing-addendum</a>, or a separately executed Data Processing Addendum (either of the aforementioned, as applicable “DPA”), will apply to any Customer Personal Data (as such term is defined in the DPA) included in Customer Data.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.6. Administration of Customer’s Account</b>. Customer acknowledges that it retains administrative control over to whom it grants access to Customer Data hosted in the Service. Customer may specify one or more Authorised Users as administrators (each an “Administrator”) to manage its account, and Hoppa is entitled to rely on communications from such Administrators and other Customer employees when servicing Customer’s account. Customer is responsible for use of the Service by its Authorised Users and for their compliance with this Agreement. Customer is solely responsible for the accuracy, quality, and legality of Customer Data. Customer will promptly notify Hoppa if it becomes aware of any unauthorised use or access to Customer’s account or the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.7. Location</b>. The Service will be provided from within the United Kingdom, unless otherwise agreed. Customer Data will be stored in the United Kingdom, unless otherwise agreed. 
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            3. WARRANTIES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.1. Mutual Warranties</b>. Each party represents and warrants that it will comply with all laws, rules, and regulations applicable to the exercise of its rights and performance of its obligations under this Agreement.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.2. By Hoppa</b>.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.2.1. Service Warranties</b>. Hoppa Warrants the Service will be provided using the reasonable skill and care to be expected of a competent and qualified consultant experienced in performing services of a similar scope, nature and complexity to the Service for the fees stated in the Proposal.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.2.2. Malicious Code</b>. Hoppa warrants that it has implemented appropriate technical measures and updates the Service periodically to prevent the introduction of software viruses, worms, logic bombs, Trojan horses or other code, files, or scripts intended to do harm to the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.3. By Customer</b>. Customer represents and warrants that it is entitled to transfer Customer Data to Hoppa so that Hoppa and its authorised third-party service providers may lawfully use, process, and transfer the Customer Data in accordance with this Agreement on Customer’s behalf.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.4. Disclaimer</b>. The warranties set forth in this Section 3 are the exclusive warranties from Hoppa and, to the fullest extent permitted by applicable law, Hoppa does not make any additional warranties of any kind, whether express, implied, statutory, or otherwise, including warranties of merchantability, fitness for a particular use, noninfringement, or error-free or uninterrupted use of the Service or Professional Services. Hoppa warranties will not apply in the event of misuse or modification of the Service by Customer and End Users, or failure to comply with instructions provided by Hoppa. Hoppa makes beta, trial, proof of concept, and “sandbox” versions of the Service available as-is without warranties of any kind.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            4. BILLING AND PAYMENTS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>4.1. Payment</b>. Customer will pay all amounts due under the applicable Proposal within fourteen (14) days of invoice date, which shall be the same date the invoice email is received by Customer. Payment Notices stating the amount of payment and the basis of its calculation are to be issued by the Customer not later than 5 days after the Payment Due date. Any Pay Less Notice stating an intention to withhold payment, its value, reasons and basis of calculation is to be issued by the Customer not less than 1 day before the Final Date for Payment. Hoppa will be entitled following a minimum of 7 days’ notice in writing to the Customer to suspend performance and delivery of the Service if the Customer fails to make full and proper payment in accordance with these provisions. 
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>4.2. Late Payment</b>. Without prejudice to any other right or remedy, Hoppa shall be entitled to interest upon late payment at the Bank of England base rate plus 8% per annum.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>4.3. Currency</b>. All amounts and Fees stated or referred to in this agreement shall be payable in pounds sterling, are non-cancellable and non-refundable.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            5. COMPLAINTS AND DISPUTES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>5.1. Complaints</b>. Hoppa treats all complaints seriously and prefers to deal with them at source and between the individuals concerned. Any dispute which cannot be settled amicably shall, in the first instance, be referred to the respective Chief Executive of the Customer and Hoppa who shall attempt, in good faith, to resolve the matter.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>5.2. Disputes</b>. In the event of the dispute not being resolved under 5.1 above the matter shall be referred to adjudication in accordance with the Construction Industry Council Model Adjudication Procedure current at the time of referral.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>5.3. Jurisdiction</b>. Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            6. TERM AND TERMINATION
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>6.1. Term</b>. This agreement shall, unless otherwise terminated as provided for herein, commence on the Effective Date and shall continue for until completion of the Service and final payment has been received by Hoppa from the Customer.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>6.2. Termination</b>. Either party may terminate this Agreement and/or any Proposal: (a) upon thirty (30) days written notice if the other party is in material breach of this Agreement and fails to cure such breach within the notice period, (b) with immediate effect upon receipt of notice in the event of a breach of Section 2.4 (Acceptable Use Terms); or (c) with immediate effect if the other party ceases its business operations or becomes subject to insolvency proceedings and the proceedings are not dismissed within sixty (60) days.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>6.3. Effect of Termination</b>. Upon expiration or termination of this Agreement and/or the applicable Proposal for any reason, all Service and any other rights granted to Customer under such terminated Proposal will immediately terminate, and Customer will immediately cease to use the Service. Upon termination of an applicable Proposal by Customer pursuant to Section 6.2(a), Hoppa will refund to Customer for the portion of work undelivered, minus costs incurred to the date notice was given. In no event will any termination relieve Customer of the obligation to pay any fees accrued or payable to Hoppa for the Service in the period prior to the effective date of termination. Any terms that by their nature extend beyond the Agreement termination remain in effect until fulfilled and apply to successors and assignees.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>6.4. Treatment of Customer Data Following Expiration or Termination</b>. Customer agrees that following expiration or termination of all Subscriptions under the Agreement, Hoppa may immediately deactivate Customer’s account(s) associated with such Agreement. Hoppa will make Customer Data available to Customer for export as long as Hoppa receives written notice within thirty (30) days after the effective date of expiration or termination from Customer. After such thirty (30) day period, Hoppa will have no obligation to retain Customer Data and will thereafter, unless legally prohibited, be entitled to delete all Customer Data in its systems or otherwise in its possession or under its control. Subject to any limitations in Customer’s Service configuration, upon Customer’s request at support@hoppa.ai, Hoppa will, within one-hundred and eighty (180) days of receipt of such request, securely destroy all Customer Data from its systems; provided that all back-ups will be deleted within thirty (30) days after such one-hundred and eighty (180) day period.
                    </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            7. CONFIDENTIALITY
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>7.1. Definition of Confidential Information</b>. During the course of performance under this Agreement, each party may make available to the other party information that is identified as, or should reasonably be understood by the receiving party to be, proprietary or confidential (the “Confidential Information”). Confidential Information specifically includes this Agreement, the Service, Proposal(s), Customer Data, business plans, product plans and roadmaps, strategies, forecasts, projects and analyses, financial information and fee structures, business processes, methods and models, and technical documentation. Confidential Information does not include information that is: (a) publicly available when received, or subsequently becomes publicly available through no fault of the receiving party; (b) obtained by receiving party from a source other than the disclosing party without obligation of confidentiality; (c) developed independently by the receiving party; or (d) already in the possession of the receiving party without obligation of confidentiality.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>7.2. Protection of Confidential Information</b>. The receiving party will use the same care and discretion to avoid disclosure, publication, or dissemination of the disclosing party’s Confidential Information as it uses with its own similar information that it does not wish to disclose, publish, or disseminate, but in no event less than a reasonable degree of care. The receiving party may disclose Confidential Information to its employees, Affiliates, consultants, subcontractors, or advisors who have a need to know such Confidential Information for the purpose of performing under this Agreement and only to those who are obligated to maintain the confidentiality of such Confidential Information upon terms at least as protective as those contained in this Agreement. If the parties entered into a non-disclosure agreement prior to executing this Agreement, the terms of this Section 7 (Confidentiality) will control in the event of any conflict or inconsistency.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>7.3. Compelled Disclosure</b>. The receiving party may disclose Confidential Information to the extent required by law or court order. However, subject to applicable law, the receiving party will give the disclosing party prompt notice to allow the disclosing party a reasonable opportunity to obtain a protective order.
                    </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            8. INTELLECTUAL PROPERTY
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>8.1. Hoppa Ownership</b>. As between the parties, Hoppa and its licensors exclusively own all right, title, and interest in and to all intellectual property rights in the Service. Customer’s use of the Service will not affect Hoppa’s ownership or license rights in the Service. Except for the rights expressly granted in Section 2.2, Hoppa reserves all rights in the Service and does not grant Customer or its Authorised Users any intellectual property rights to the Service, including any enhancements, modifications or derivatives thereof.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>8.2. Customer Ownership</b>. As between the parties, Customer and its licensors will retain all right, title, and interest in and to all intellectual property rights in Customer Data. Customer grants to Hoppa and its authorised third party Subprocessors a royalty-free, fully paid, non-exclusive, non-transferrable (except under Section 11.8), worldwide, right to use, host, store, backup, transmit, and display Customer Data solely to (a) provide and support the Service and (b) improve the Service as long as neither Customer nor its End Users are publicly identified and such data is used in a statistical or aggregated form.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>8.3. Feedback</b>. If Customer submits any feedback to Hoppa regarding the Service or Professional Services, Hoppa may use such feedback for any purpose without any compensation or obligation to Customer provided such use does not violate Section 6 (Confidentiality).
                    </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            9. INDEMNIFICATION
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>9.1. Hoppa Indemnity</b>. Hoppa will (a) defend Customer and its Affiliates and their respective officers, directors, and employees against any third party claims, actions, demands or suits to the extent arising out of or alleging the Service as used by the Customer in accordance with this Agreement infringes, misappropriates or violates such third party’s intellectual property rights (“IP Claim”) and (b) with relation to the IP Claim, pay amounts finally awarded by a court against Customer or included in a settlement approved by Hoppa. To obtain such defense and payment by Hoppa, Customer must promptly (i) notify Hoppa in writing of the claim, (ii) supply information requested by Hoppa, and (iii) allow Hoppa to control, and reasonably cooperate in, the defense and settlement, including mitigation efforts. <div><br></div>
                    In connection with any IP Claim, Hoppa may: (a) contest the IP Claim; (b) obtain claimant’s permission for Customer’s continued use of the applicable Service; (c) replace Customer’s access to or use of the applicable Service with substantially similar functionality that avoids the IP Claim; or (d) if Hoppa determines the foregoing clauses (a), (b), and (c) are commercially impracticable, terminate Customer’s access to and use of the affected Service on sixty (60)-days’ prior notice and refund any prepaid subscription fees covering that part of the then-current Subscription Term for such Service measured from the effective date of termination.
                    <div><br></div>
                    Hoppa has no responsibility for claims based on non-Hoppa products and services, items not provided by Hoppa, or any violation of law or third party rights caused by Customer Data or other Customer materials.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>9.2. Customer Indemnity</b>. Customer will (a) defend Hoppa and its Affiliates and their respective officers, directors, and employees against any third party claims, actions, demands or suits to the extent arising out of or alleging that Customer Data infringes, misappropriates or violates a third party’s rights including but not limited to intellectual property rights, privacy rights, or rights of publicity and (b) with relation to (a), pay amounts finally awarded by a court against Hoppa or included in a settlement approved by Customer. To obtain such defense and payment by Customer, Hoppa must promptly (i) notify Customer in writing of the claim, (ii) supply information requested by Customer, and (iii) allow Customer to control, and reasonably cooperate in, the defense and settlement, including mitigation efforts.
                    <div><br></div>
                    This Section states each party’s entire obligation and exclusive remedy regarding the third party claims described in the Section.

                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            10. LIABILITY
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.1. Limits</b>. To the extent permitted by law, each party’s total, cumulative liability for all claims arising out of or related to the Agreement, whether based on contract, tort (including negligence) or any other legal or equitable theory will be limited to the total amount paid by Customer to date under this Agreement in the six (6) months preceding the first event giving rise to liability.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.2. Exclusions</b>. To the extent permitted by law, neither party will be liable for (a) special, incidental, exemplary, punitive, indirect, or any consequential damages, or (b) lost profits (direct or indirect), for loss of use or data, service interruption, business, value, revenue, goodwill, or anticipated savings whether based on contract, tort (including negligence) or any other legal or equitable theory, even if such party has been advised of such damages in advance or if such damages were foreseeable.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.3. The following are not subject to the limitation in Section 10.1 and the exclusion in Section 10.2: (a) obligations to pay for products, services, or taxes set forth in Section 4, (b) a party’s indemnification payments set forth in Section 9 (Indemnification), and (c) damages arising from a party’s gross negligence, wilful misconduct, or fraud, separate and distinct from a cause of action for a breach of this Agreement.</b>.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.4. Net Contribution</b>. The liability of Hoppa for any loss or damage under this Agreement shall not exceed such sum as it would be just and equitable for Hoppa to pay having regard to the extent of its responsibility for the loss and/or damage in question when compared with the responsibilities of contractors, sub-contractors, consultants and other persons responsible for that loss and/or damage.
                    </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            11. VARIATIONS OF SERVICE
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>11.1. Notification</b>. Both parties will bring to the attention of the other any additional services required of Hoppa which are considered to be outside the Services described in the Proposal.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>11.2. Rates</b>. Unless and until agreed to the contrary, additional services will be charged at hourly rates stated in the Proposal or, at rates analogous thereto. If there are no hourly rates stated in the Proposal or if there are no rates upon which charges can be based, then rates which are fair and reasonable will be charged.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>11.3. Instruction to proceed</b>. With the exception of actions or failure to act having an impact on safety or compliance with legislation, additional services will only be undertaken with the Customer’s agreement, confirmed in writing. Where issues of safety or compliance with legislation are involved, Hoppa will notify the Customer of necessary variations at the earliest opportunity.
                    </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            12. MISCELLANEOUS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.1. Governing Law</b>. This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including noncontractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.2. Notices</b>. Hoppa may give general notices related to the Service that apply to all customers by email, in-app notifications, or posting them through the Service. Other notices under the Agreement must be in writing and sent to the business mailing or email address specified in writing by Customer, such as in the Proposal as applicable. Notices are deemed given when received.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.3. Publicity</b>. We often promote our business and its services by using specific examples of the work we have done and the services we have provided to our Customers. We may refer to the work or services we will provide to Customer in future publicity and Customer grants us permission to use the following elements for our publicity purposes; we may include naming you as a customer of ours, naming the other parties and contractors involved in your project, providing a summary of the work undertaken, identifying the land or property concerned and showing photographs and extracts from plans from the project.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.4. Access to Non-Production Versions of the Service</b>. Customer may be provided with access to beta, trial, proof of concept, or sandbox versions of the Service or features within the Service (collectively, the “Non-Production Versions of the Service”). Customer acknowledges and understands that its use of the Non-Production Versions of the Service is not required and is at Customer’s own risk, and that Non-Production Versions of the Service are made available on an “as is” basis without warranties of any kind, may be discontinued or modified at any time, and may be subject to other terms. Non-Production Versions of the Service are not for production use, not supported, and not subject to availability or security obligations. Hoppa will have no liability for any harm or damage arising out of or in connection with Non-Production Versions of the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.5. Relationship of the Parties</b>. Hoppa is an independent contractor, not Customer’s agent, joint venturer, partner, or fiduciary. No right or cause of action for any third party is created by the Agreement or any transaction under it.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.6. Force Majeure</b>. Neither party is responsible for failure to fulfil its non-monetary obligations due to causes beyond its control.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.7. Severability; No Waiver</b>. If any provision of the Agreement is invalid or unenforceable, the remaining provisions remain in full force and effect.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.8. Assignment</b>. Neither party may assign the Agreement, in whole or in part, without the prior written consent of the other. However, either party may assign the Agreement to its Affiliate or to its successor in interest in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.9. Modifications</b>. Hoppa may revise this Agreement from time to time by posting the modified version on its website. By continuing to access or use the Service after the posted effective date of modifications to this Agreement, Customer agrees to be bound by the revised version of the Agreement. If you have a separate written agreement with Hoppa on this subject matter, these online Subscriber Terms will not apply to the Customer.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.10. Third-Party Rights</b>. This agreement does not confer any rights on any person or party (other than the parties to this agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>12.11. Conflict</b>. If there is an inconsistency between any of the provisions in the main body of this agreement and the Proposal, the provisions in the main body of this agreement shall prevail.
                    </span>
                </span>
            </span>
            <div><br></div>
                        `
    return (
        <div >
            <NavBar variant='blue' />
            <div className="full-width-primary-section">
                <Container>
                    <Container className='rounded-box' style={{ marginTop: '9%', padding: '4vw' }}>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Container>
                </Container>
                <footer>
                    <FooterBar />
                </footer>
            </div>
        </div>
    )
}

export default TermsAndConditions;
