import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Button, Form, OverlayTrigger, Tooltip, Badge, Modal, Row, Col } from 'react-bootstrap';
import { Box } from '@mui/material';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import DefineWorkspaceStandard from '../DefineWorkspaceStandard';
import CustomiseWorkspaceStandard from '../CustomiseWorkspaceStandard';
import { useWorkspaceStandard } from '../../../contexts/InitiateContexts';
import { useSession } from '../../../contexts/SessionContexts';
import '../../../App.scss';
import ReactGA from 'react-ga';

import {
    useNavigate,
    useParams,
} from "react-router-dom";
import CustomAlert from '../../shared/CustomAlert';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InitiateWorkspace = ({handleMenuSelection, fetchWorkspaces}) => {
    const { user } = useAuth0();
    const { sessionId } = useParams();
    const { currentOrg, workspace } = useParams();
    const navigate = useNavigate();

    const { workspaceStandard } = useWorkspaceStandard();
    const { setSession } = useSession();
    const [currentStep, setCurrentStep] = useState(0);
    const [validated, setValidated] = useState(false);
    const [firstTimeConfig, setFirstTimeConfig] = useState(true);
    const [existingTemplate, setExistingTemplate] = useState(null);
    const [newTemplate, setNewTemplate] = useState(null);
    const [clearStandard, setClearStandard] = useState(false);
    const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showStandardHelp, setShowStandardHelp] = useState(false);
    const [showCustomiseHelp, setShowCustomiseHelp] = useState(false);
    const [allCodePartsHaveValues, setAllCodePartsHaveValues] = useState(false)
    const [hasCodeParts, setHasCodeParts] = useState(false)
    const [uploadFromExcel, setUploadFromExcel] = useState(false)
    const cancelTokenSource = useRef(null); // To handle cancel requests
    const [fileOperationInProgress, setFileOperationInProgress] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    // New states
    const [blueprints, setBlueprints] = useState([]);
    const [isLoadingBlueprints, setIsLoadingBlueprints] = useState(false);
    const [blueprintLoadError, setBlueprintLoadError] = useState("")
    const [selectionMade, setSelectionMade] = useState(false);

    const [continueEnabled, setContinueEnabled] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed

    useEffect(() => {
        console.log("ALL CODE PARTS HAVE VALS", allCodePartsHaveValues)
        console.log("HAS CODE PARTS", hasCodeParts)
    }
    )

    const steps = ['Choose a standard', 'Customise to suit'];

    const config = {
        headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY }
    };

    // Get blueprints from blob
    useEffect(() => {
        const fetchBlueprints = async () => {
            setIsLoadingBlueprints(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/api/GetBlueprintsTrigger`, config);
                setBlueprints(response.data);
            } catch (err) {
                console.error("Error fetching blueprints:", err);
                setBlueprintLoadError(err);
            } finally {
                setIsLoadingBlueprints(false);
            }
        };
        fetchBlueprints();
    }, []);

    console.log(blueprints)

    const isStepOptional = (step) => {
        return step === 1 || step === 2; // Step 2 and 3 are optional. 
    };

    // Google Analytics tracking
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const toggleStandardHelp = () => setShowStandardHelp(!showStandardHelp);
    const toggleCustomiseHelp = () => setShowCustomiseHelp(!showCustomiseHelp);

    const handleStepChange = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const stepIndex = currentStep + 1;

        // Trigger form validation
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        // Handle first-time config or ask for confirmation
        if (firstTimeConfig) {
            setExistingTemplate(newTemplate); // Set existing template from selected blueprint
            setCurrentStep(currentStep + 1);
        } else if (existingTemplate === newTemplate) {
            setCurrentStep(currentStep + 1);
        } else {
            setShowOverwriteConfirmation(true); // Confirm if overwriting existing template
        }
    };

    const overwriteAllCodeParts = () => {
        setExistingTemplate(newTemplate);
        setClearStandard(true);
        setShowOverwriteConfirmation(false);
        setCurrentStep(currentStep + 1);
    };

    const handleSetTemplate = (template) => {
        setNewTemplate(template);
    };

    const handleSetFirstTimeConfig = () => {
        setFirstTimeConfig(false);
    };

    const handleSetClearStandard = (boolean) => {
        setClearStandard(boolean);
    };

    const handleSetToastMessage = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    console.log("workspace standard", workspaceStandard)

    const handleSessionCommit = async (event) => {

        setShowOverlay(true)

        const workspaceStandardJSON = JSON.stringify(workspaceStandard);
        const workspaceStandardJSONforBlob = new Blob([workspaceStandardJSON], { type: 'application/json' });

        const formData = new FormData();
        formData.append('file', workspaceStandardJSONforBlob, 'workspace_standard.json');
        formData.append('organisation', currentOrg);
        formData.append('workspace', workspace);

        cancelTokenSource.current = axios.CancelToken.source();

        try {
            setFileOperationInProgress(true); // Set the operation in progress flag

            // Perform the POST request to upload the file
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/CompleteWorkspaceConfigTrigger`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Add function key if necessary
                    },
                    cancelToken: cancelTokenSource.current.token,
                }
            );

            // Handle success response
            console.log('File uploaded successfully:', response.data);
            triggerAlert('Workspace standard saved successfully!'); // Show a success message
        } catch (error) {
            console.error('Error uploading file:', error);
            triggerAlert('Error uploading workspace standard.', 'Error');
        } finally {
            setFileOperationInProgress(false); // Reset file operation progress
            setShowOverlay(false); // Hide overlay or loading indicator
        }
        handleMenuSelection(`${currentOrg}/${workspace}`,'workspace')
        fetchWorkspaces(currentOrg)
    };

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true); // This will trigger the alert to show
    };

    const renderModalContent = (
        <>
            {showStandardHelp && (
                <Modal.Body>
                    <b>Why is this step important?</b>
                    <br /><br />
                    Information management in the built environment involves organising, storing, retrieving, and utilising data related to construction projects. It enhances collaboration and decision-making throughout the lifecycle of built assets.
                    <br /><br />
                    <b>Where to start?</b>
                    <br /><br />
                    Many organisations expect files in a format complying with specific standards like ISO 19650. If you need help building your standard, feel free to reach out at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>.
                </Modal.Body>
            )}
            {showCustomiseHelp && (
                <Modal.Body>
                    <b>Why is this step important?</b>
                    <br /><br />
                    Customising your standard ensures your files are organised in a way that suits your project. Define classifiers, set reference codes, and add descriptions to guide how files are classified.
                    <br /><br />
                    <b>Got a request or need help?</b>
                    <br /><br />
                    Reach out to <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> if you need help.
                </Modal.Body>
            )}
        </>
    );

    return (
        <div style={{ display: 'flex', flexGrow: 1 }}>
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide className="position-fixed top-0 start-50 translate-middle-x">
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <Form noValidate validated={validated} onSubmit={(event) => handleStepChange(event)} style={{ height: '100%' }}>
                <Container className="pt-3 mb-5">
                    <div className={currentStep === 0 ? '' : 'hidden'}>
                        <DefineWorkspaceStandard
                            blueprints={blueprints}
                            handleSetTemplate={handleSetTemplate}
                            setHasCodeParts={setHasCodeParts}
                            setUploadFromExcel={setUploadFromExcel}
                            setContinueEnabled={setContinueEnabled}
                        />
                    </div>
                    <div className={currentStep === 1 ? '' : 'hidden'}>
                        <CustomiseWorkspaceStandard
                            blueprints={blueprints}
                            workspaceStandard={workspaceStandard}
                            template={existingTemplate}
                            firstTimeConfig={firstTimeConfig}
                            handleSetFirstTimeConfig={handleSetFirstTimeConfig}
                            clearStandard={clearStandard}
                            handleSetClearStandard={handleSetClearStandard}
                            step={currentStep}
                            setHasCodeParts={setHasCodeParts}
                            hasCodeParts={hasCodeParts}
                            setAllCodePartsHaveValues={setAllCodePartsHaveValues}
                            handleSetToastMessage={handleSetToastMessage}
                        />
                    </div>
                </Container>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        height: '64px', // Adjust the height as needed
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                        padding: '0 1.5rem'
                    }}
                >
                    {/* Cancel Button */}
                    {currentStep > 0 && (
                        <Button
                            variant="text"
                            sx={{ color: '#999999', textTransform: 'none' }}
                            onClick={() => setCurrentStep(currentStep - 1)}
                        >
                            Back
                        </Button>
                    )}
                    {/* Continue Button */}
                    <button
                        className='btn btn-primary mt-2'
                        onClick={currentStep === steps.length - 1 ? handleSessionCommit : undefined}
                        disabled={currentStep === 0 ? !continueEnabled : !(allCodePartsHaveValues && hasCodeParts)}
                    >
                        {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                    </button>
                </Box>
            </Form>
            <Modal show={showOverwriteConfirmation} onHide={() => setShowOverwriteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to overwrite all code parts?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowOverwriteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={overwriteAllCodeParts}>
                        Overwrite All
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showStandardHelp || showCustomiseHelp} onHide={toggleStandardHelp || toggleCustomiseHelp} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Help</Modal.Title>
                </Modal.Header>
                <Modal.Body>{renderModalContent}</Modal.Body>
            </Modal>
            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
        </div>
    );
};

export default InitiateWorkspace;
