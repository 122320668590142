import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import WelcomeUser from './components/WelcomeUser'
import OrganisationLanding from './components/OrganisationLanding'
import Initiate from './components/Initiate'
import InitiateWorkspace from './components/InitiateWorkspace'
import Workspace from './components/Workspace'
import Results from './components/Results'
import CookiePolicy from './components/CookiePolicy'
import PrivacyPolicy from './components/PrivacyPolicy'
import BackupPolicy from './components/BackupPolicy'
import TermsAndConditions from './components/TermsAndConditions'
import NotFoundPage from './components/NotFoundPage';
import AccessDeniedPage from './components/AccessDeniedPage';
import Callback from './components/Callback';
import Authorize from './components/Authorize';
import CreateSession from './components/CreateSession';
import TextToUniclass from './components/TextToUniclass';
import Login from './components/Login';
import { UserManagementProvider } from './contexts/UserManagementContexts';
import { UserStandardProvider } from './contexts/InitiateContexts';
import { WorkspaceStandardProvider } from './contexts/InitiateContexts';
import { SessionProvider } from './contexts/SessionContexts';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './index.scss';
import Dashboard from './components/Dashboard';
import Integrations from './components/Integrations';
import DataProcessingAddendum from './components/DataProcessingAddendum';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
}

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <SessionProvider>
          <UserManagementProvider>
            <UserStandardProvider>
              <WorkspaceStandardProvider>
                <AnalyticsTracker />
                <Routes>
                  {/* Public Routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/cookies" element={<CookiePolicy />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/backup-policy" element={<BackupPolicy />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/data-processing-addendum" element={<DataProcessingAddendum />} />
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="/access-denied" element={<AccessDeniedPage />} />
                  <Route path="/callback/:action/:service" element={<Callback />} />
                  <Route path="/authorize" element={<Authorize />} />
                  <Route path="/integrations" element={<Integrations />} />
                  {/* Private Routes */}
                  <Route path="/welcome" element={<OrganisationLanding />} />
                  <Route path="/welcomeUser" element={<WelcomeUser />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/dashboard/:region/:currentOrg/:workspace" element={<Dashboard />} />
                  <Route path="/:userId/:sessionId/initiate" element={<Initiate />} />
                  <Route path="/:currentOrg/:workspace/createSession" element={<CreateSession />} />
                  <Route path="/:currentOrg/:workspace/initiateWorkspace" element={<InitiateWorkspace />} />
                  <Route path="/:currentOrg/:workspace/workspace" element={<Workspace />} />
                  <Route path="/:userId/:sessionId/results" element={<Results />} />
                  <Route path="/:currentOrg/:workspace/:sessionId/results" element={<Results />} />
                  <Route path="/text-to-uniclass" element={<TextToUniclass />} />
                  <Route path="/login" element={<Login />} />
                </Routes>
              </WorkspaceStandardProvider>
            </UserStandardProvider>
          </UserManagementProvider>
        </SessionProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
