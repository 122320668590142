import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";

const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [organizations, setOrganizations] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [error, setError] = useState(null);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(true);
  const [hasOrgs, setHasOrgs] = useState('unknown');

  const { currentOrg: currentOrgParam } = useParams();

  // Global Navigation
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (isAuthenticated && user && user.sub) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/v2/users/${user.sub}/permissions`,
            { headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY } }
          );

          const { organizations: orgs } = response.data;
          setOrganizations(orgs);

          // Update hasOrgs based on fetched organizations
          setHasOrgs(orgs.length > 0);

          // if (!currentOrg && orgs.length > 0) {

          //   console.log(currentOrgParam)
          //   if (currentOrgParam) {
          //     console.log('Good Trigger')
          //     setCurrentOrg(orgs.find(org => org.name === currentOrgParam));
          //   } else {
          //     console.log('Bad Trigger')
          //     console.log(orgs[1])
          //     setCurrentOrg(orgs[0]);
          //   }
          // }

          const { permissions: perms } = response.data.global;
          setPermissions(perms);

        } catch (error) {
          console.error('Error fetching organizations:', error);
          setError('Failed to fetch organizations.');
        } finally {
          setIsLoadingOrgs(false);
        }
      }
      // else if (!isLoading) {        
      //   navigate('')
      // }
    }

    fetchOrganizations();
  }, [isLoading, isAuthenticated, user, API_BASE_URL]);

  useEffect(() => {
    if (currentOrg) {
      // Additional logic based on currentOrg can be added here
    }
  }, [currentOrg]);

  return (
    <UserManagementContext.Provider
      value={{
        organizations,
        setOrganizations,
        currentOrg,
        setCurrentOrg,
        isLoadingOrgs,
        error,
        user,
        isLoading,
        permissions,
        hasOrgs // Provide hasOrgs in context
      }}>
      {children}
    </UserManagementContext.Provider>
  );
};

// Custom hook for using the UserManagementContext
export const useUserManagement = () => {
  return useContext(UserManagementContext);
};

export function useCheckPermissions() {
  const { organizations, permissions, user } = useUserManagement();

  function checkPermissions(requestedPermissions, organization) {
    // If organization is provided, check if an organization with the specified name exists
    let matchedOrganization

    if (organization) {
      matchedOrganization = organizations.find(org => org.name === organization);
      if (!matchedOrganization) {
        return false;
      }
    }

    // Loop through each requested permission
    for (let requestedPermission of requestedPermissions) {
      // Check if a global permission with the requested permission_name is present
      const globalPermissionExists = permissions?.some(perm => perm.permission_name === requestedPermission);
      if (!globalPermissionExists) {
        if (matchedOrganization) {
          const permissionExists = matchedOrganization.permissions?.some(perm => perm.permission_name === requestedPermission);
          if (!permissionExists) {
            return false;
          }
        } else {
          return false
        }
      }
    }

    // All checks passed, return true
    return true;
  }

  return checkPermissions;
}
