import React, { useState, useEffect } from 'react';
// import { Button, Card, ProgressBar, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faCircle, faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { localeOption } from 'primereact/api';
import { Card, Stack, CardContent, Typography, Box, CardActionArea, Chip, IconButton } from '@mui/material';
// import Chip from '@mui/material/Chip';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';


const FileCard = ({ fileName, fileType, total, uploadProgress, handleDelete, status }) => {
    const [statusIcon, setStatusIcon] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {

        if (status === 'processing') {
            setShowSpinner(true)
        } else {
            setShowSpinner(false)
        }

    }, [status])

    useEffect(() => {
        if (status === 'processing' && uploadProgress > 99) {
            setStatusIcon(
                <Chip variant='outlined' label='Writing to storage...' className='me-3' />
            )
        }
        else {
            setStatusIcon(
                <div className='d-flex align-items-center'>
                    {status === 'failed' ? <Chip color='error' variant='outlined' label='Upload failed' className='me-3' /> : <></>}
                    {handleDelete &&
                        <IconButton onClick={() => handleDelete(fileName)} >
                            <FontAwesomeIcon size='sm' icon={faTrash} className='me-1' />
                        </IconButton>
                    }
                </div>
            )
        }
    }, [status, uploadProgress, fileName, handleDelete])

    const formatSize = (bytes) => {
        const k = 1024;
        let dm = 1;
        const sizes = localeOption('fileSizeTypes');

        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        // Display zero decimal places if file size is less than 1MB. 
        if (bytes < k * k) {
            dm = 0
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    };

    return (
        <div className="pb-1 pe-1" style={{ width: '100%' }}>
            <Card
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '80px',
                    padding: '10px',
                }}
            >
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '50px', }}>
                        <div style={{ width: '50px', minWidth: '50px', marginRight: '10px' }}>
                            <FileIcon
                                extension={fileType}
                                {...defaultStyles[fileType]}
                                labelUppercase
                            />
                        </div>
                        <Stack direction="column" spacing={0} sx={{ overflow: 'hidden', minWidth: 0, }}>
                            <div
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    display: 'block',
                                    minWidth: 0,

                                }}
                            >
                                {fileName}
                            </div>
                            <div>
                                {total ? (
                                    <Chip
                                        label={formatSize(total)}
                                        sx={{ color: "#544cfd", borderColor: "#544cfd" }}
                                        variant="outlined"
                                    />
                                ) : null}

                            </div>
                        </Stack>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {statusIcon}
                            {showSpinner && (
                                <CircularProgressWithLabel value={uploadProgress} suffix={'%'} />
                            )}
                        </div>
                    </div>
                </Stack>
            </Card>
        </div >

    );
};

export default FileCard;