import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, ListGroup, Nav, Badge } from 'react-bootstrap';
import { FaSearch, FaTimes } from 'react-icons/fa';
import TreeView from '../shared/TreeView';  // Ensure TreeView is correctly imported

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CategoryHub = ({blueprints}) => {
  const [selectedBlueprint, setSelectedBlueprint] = useState(null);
  const [selectedClassifier, setSelectedClassifier] = useState(null);
  const [showPanels, setShowPanels] = useState(false);
  const [searchTermBlueprints, setSearchTermBlueprints] = useState('');
  const [searchTermClassifiers, setSearchTermClassifiers] = useState('');
  const [searchTermValues, setSearchTermValues] = useState('');
  const [showBlueprintSearch, setShowBlueprintSearch] = useState(false);
  const [showClassifierSearch, setShowClassifierSearch] = useState(false);
  const [showValuesSearch, setShowValuesSearch] = useState(false);
  const [showClassifiers, setShowClassifiers] = useState(false);
  const [showClassifierValues, setShowClassifierValues] = useState(false);

  // const [blueprints, setBlueprints] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(null);

  const config = {
    headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY }
  };

  // useEffect(() => {
  //   const fetchBlueprints = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/api/GetBlueprintsTrigger`, config);
  //       setBlueprints(response.data);
  //     } catch (err) {
  //       console.error("Error fetching blueprints:", err);
  //       setError(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchBlueprints();
  // }, []);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error fetching blueprints: {error.message}</div>;

  const handleBlueprintClick = (blueprint) => {
    setSelectedBlueprint(blueprint);
    setSelectedClassifier(null);
    setShowClassifierValues(false);
    setShowPanels(true);
    setShowClassifiers(true);
  };

  const handleClassifierClick = (classifier) => {
    setSelectedClassifier(classifier);
    setShowClassifierValues(true);
  };

  const handleBack = () => {
    setShowPanels(false);
    setSelectedBlueprint(null);
    setSelectedClassifier(null);
    setShowClassifiers(false);
    setShowClassifierValues(false);
  };

  const handleCollapseClassifiers = () => {
    setShowClassifiers(false);
    setShowClassifierValues(false);
  };

  const handleCollapseClassifierValues = () => {
    setShowClassifierValues(false);
  };

  // Filter blueprints based on search term
  const filteredBlueprints = blueprints.filter(bp => 
    bp.name && bp.name.toLowerCase().includes(searchTermBlueprints.toLowerCase())
  );

  const visibleClassifiers = selectedBlueprint
    ? Object.values(selectedBlueprint.classifiers).filter(classifier =>
        classifier.name && classifier.name.toLowerCase().includes(searchTermClassifiers.toLowerCase())
      )
    : [];

  const filteredValues = selectedClassifier
    ? selectedClassifier.data
        .filter(item =>
          (item.value && item.value.toLowerCase().includes(searchTermValues.toLowerCase())) ||
          (item.description && item.description.toLowerCase().includes(searchTermValues.toLowerCase())) ||
          (item.code && item.code.toLowerCase().includes(searchTermValues.toLowerCase()))
        )
        .map(item => {
          const isHierarchy = selectedClassifier.isHierarchy;
          const nodeKey = isHierarchy
            ? `${selectedClassifier.id}_${item.parent || ''}_${item.code}`
            : `${selectedClassifier.id}_${item.code}`;
          return { ...item, nodeKey, parent: item.parent ? `${item.parent}` : null };
        })
    : [];

  return (
    blueprints && 
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {/* First Panel: Blueprints */}
      <div style={{
        width: showPanels ? '33%' : '100%',
        borderRight: '1px solid #ccc',
        padding: '10px',
        transition: 'width 0.3s ease-in-out'
      }}>
        <Nav className="mb-3">
          <Badge pill variant="primary" onClick={handleBack} style={{ cursor: 'pointer' }}>
            Blueprints
          </Badge>
          <FaSearch onClick={() => setShowBlueprintSearch(!showBlueprintSearch)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
        </Nav>
        {showBlueprintSearch && (
          <div className="d-flex align-items-center mb-2">
            <Form.Control
              type="text"
              placeholder="Search Blueprints"
              value={searchTermBlueprints}
              onChange={(e) => setSearchTermBlueprints(e.target.value)}
            />
            <FaTimes onClick={() => setSearchTermBlueprints('')} style={{ cursor: 'pointer', marginLeft: '5px' }} />
          </div>
        )}
        <ListGroup>
          {filteredBlueprints.map(blueprint => (
            <ListGroup.Item
              key={blueprint.name}
              action
              onClick={() => handleBlueprintClick(blueprint)}
              active={selectedBlueprint === blueprint}
            >
              {blueprint.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      {/* Second Panel: Classifiers */}
      {selectedBlueprint && showClassifiers && (
        <div style={{
          width: '33%',
          borderRight: '1px solid #ccc',
          padding: '10px',
          transition: 'width 0.3s ease-in-out, opacity 0.3s ease-in-out',
          opacity: showClassifiers ? 1 : 0
        }}>
          <Nav className="mb-3">
            <Badge pill variant="success" onClick={handleCollapseClassifierValues}>
              Categories
            </Badge>
            <FaSearch onClick={() => setShowClassifierSearch(!showClassifierSearch)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
          </Nav>
          {showClassifierSearch && (
            <div className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder="Search Classifiers"
                value={searchTermClassifiers}
                onChange={(e) => setSearchTermClassifiers(e.target.value)}
              />
              <FaTimes onClick={() => setSearchTermClassifiers('')} style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </div>
          )}
          <ListGroup>
            {visibleClassifiers.map(classifier => (
              <ListGroup.Item
                key={classifier.name}
                action
                onClick={() => handleClassifierClick(classifier)}
                active={selectedClassifier === classifier}
              >
                {classifier.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}

      {/* Third Panel: Values */}
      {selectedClassifier && showClassifierValues && (
        <div style={{
          width: '33%',
          padding: '10px',
          transition: 'width 0.3s ease-in-out, opacity 0.3s ease-in-out',
          opacity: showClassifierValues ? 1 : 0
        }}>
          <Nav className="mb-3">
            <Badge pill variant="warning" onClick={handleCollapseClassifiers}>
              Values
            </Badge>
            <FaSearch onClick={() => setShowValuesSearch(!showValuesSearch)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
          </Nav>
          {showValuesSearch && (
            <div className="d-flex align-items-center mb-2">
              <Form.Control
                type="text"
                placeholder="Search Values"
                value={searchTermValues}
                onChange={(e) => setSearchTermValues(e.target.value)}
              />
              <FaTimes onClick={() => setSearchTermValues('')} style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </div>
          )}
          <TreeView
            nestedData={{ data: filteredValues }}
            dataKey="data"
            labelKey="description"
            nodeKey="nodeKey"
            childrenKey="children"
          />
        </div>
      )}
    </div>
  );
};

export default CategoryHub;
