import { Typography, Stack } from '@mui/material';
import React from 'react';

const MainHeader = ({ style, title, subtitle }) => {
    return (
        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', width: '100%', ...style}}>
            <Stack style={{maxWidth: '900px', alignItems: 'center'}}>
                <Typography variant='h1' className='blue' sx={{ fontSize: '2.8rem', lineHeight: 0.9, marginBottom: '16px' }}>
                    {title}
                </Typography>
                <Typography variant='body1' style={{maxWidth: '700px'}}>
                    {subtitle}
                </Typography>
            </Stack>
        </div>
    )
}

export default MainHeader;